import { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addROErrorLog } from "../features/globalFunctions";
import myUUid from "../features/myUUid";
import ROIcon from "../components/ROIcon";
import ShowROIcons from "../components/ShowROIcons";
import {
  formatShortTimeSeconds,
  formatShortDate,
} from "../features/formatTime.js";

import axios from "axios";

const devTesting = false;

const Dashboard = () => {
  const navigate = useNavigate();

  console.log("Dashboard.jsx Page");

  useEffect(() => {
    console.log("Dashboard.jsx Page useEffect");
  }, []);

  return (
    <>
      <section className="pageHead container">
        <section className="pageFunctions container"></section>
      </section>
      <div className="pageContent container w-75">
        <div className="container g-2 text-white">
          <div className="row align-items-stretch justify-content-center   g-3 mt-4 mb-5">
            <div className="col-lg-12 text-center" id="col1">
              <img src="/background.png" alt="Logo" />
            </div>
          </div>
          {devTesting ? <ShowROIcons useFill={true} /> : ""}
        </div>
      </div>
    </>
  );
};

export default Dashboard;

// {
//     scannedData && scannedData !== '' ? (
//         <div>
//             {/* <div>Scanned Data:</div>
//         <div>{JSON.stringify(scannedData)}</div> */}
//             <div>
//                 <input type='button' value='Scan Barcode' onClick={() => setScannedData(null)} />
//             </div>
//         </div>
//     ) : (
//         <HTML5QRscanner
//             fps={30}
//             box={{ width: 600, height: 150 }}
//             disableFlip={false}
//             successCallback={(decodedText, decodedResult) => onNewScanResult(decodedText, decodedResult)}
//             errorCallback={onNewScanError}
//         />
//     )
// }
