import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import camerasService from './camerasService'
const initialState = {
    cameras: [],
    isCamerasError: false,
    isCamerasSuccess: false,
    isCamerasLoading: false,
    camerasmessage: '',
}

// Create New Camera
export const createCamera = createAsyncThunk('camera/create', async (cameraData, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token

        console.log('createCamera(camerasSlice) cameraData: ', cameraData)

        return await camerasService.createCamera(cameraData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

// Update Camera
export const updateCamera = createAsyncThunk('camera/update', async (cameraData, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        console.log('camera Slice update cameraData: ', cameraData)
        const token = thunkAPI.getState().auth.user.token
        return await camerasService.updateCamera(cameraData, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

//Get cameras
export const getCameras = createAsyncThunk('camera/getAll', async (_, thunkAPI) => {
    try {
        let userCred = ''

        //console.log('Get All Franchise users')

        // if (thunkAPI.getState() != null) {
        //     userCred = thunkAPI.getState().auth.user
        // }

        // if (userCred.token) {
            const returnData = await camerasService.getCameras(userCred)
            return returnData
        // }

        return thunkAPI.rejectWithValue('No user token found')
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        //console.log('thunkAPI getCameras error: ', message, error)
        return thunkAPI.rejectWithValue(message)
    }
})

// Delete camera
export const deleteCamera = createAsyncThunk('camera/delete', async (id, thunkAPI) => {
    try {
        //Send payload by thunkAPI->extraReducers(builder->register(user))
        const token = thunkAPI.getState().auth.user.token
        return await camerasService.deleteCamera(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        //Send reject payload by thunkAPI->extraReducers(builder->register(message))
        return thunkAPI.rejectWithValue(message)
    }
})

export const camerasSlice = createSlice({
    name: 'cameras',
    initialState,
    reducers: {
        resetcameras: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCamera.pending, (state) => {
                state.isCamerasSuccess = false
                state.isCamerasLoading = true
                state.isCamerasError = false
            })
            .addCase(createCamera.fulfilled, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasSuccess = true
                state.isCamerasError = false
                if (!state.cameras) {
                    state.cameras = []
                }
                console.log('cameraSlice createCamera action.payload: ', action.payload)
                state.cameras.push(action.payload)
            })
            .addCase(createCamera.rejected, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasError = true
                console.log('cameraSlice createCamera rejected action.payload: ', action.payload)
                state.camerasmessage = action.payload
            })
            .addCase(updateCamera.pending, (state) => {
                state.isCamerasError = false
                state.isCamerasSuccess = false
                state.isCamerasLoading = true
            })
            .addCase(updateCamera.fulfilled, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasSuccess = true
                state.isCamerasError = false
                state.cameras = state.cameras.map((camera) => {
                    if (camera.CameraID === action.payload.CameraID) {
                        return action.payload
                    } else {
                        return camera
                    }
                })
            })
            .addCase(updateCamera.rejected, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasError = true
                //This is what redux helps to do. Change immutable values
                console.log('updateCamera(cameraSlice) error!!', action.payload)
                state.camerasmessage = action.payload
            })

            //Get Cameras
            .addCase(getCameras.pending, (state) => {
                state.isCamerasLoading = true
                state.isCamerasError = false
            })
            .addCase(getCameras.fulfilled, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasSuccess = true
                state.isCamerasError = false
                //This is what redux helps to do. Change immutable values
                state.cameras = action.payload
            })
            .addCase(getCameras.rejected, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasError = true
                //This is what redux helps to do. Change immutable values
                state.camerasmessage = action.payload
            })

            //Delete Camera
            .addCase(deleteCamera.pending, (state) => {
                state.isCamerasSuccess = false
                state.isCamerasLoading = true
                state.isCamerasError = false
            })
            .addCase(deleteCamera.fulfilled, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasSuccess = true
                state.isCamerasError = false
                console.log('camerasSlice.js Delete camera action.payload: ', action.payload)
                state.cameras = state.cameras.filter((camera) => camera.CameraID !== action.payload.id)
            })
            .addCase(deleteCamera.rejected, (state, action) => {
                state.isCamerasLoading = false
                state.isCamerasError = true
                //This is what redux helps to do. Change immutable values
                state.camerasmessage = action.payload
            })
    },
})

export const { resetcameras } = camerasSlice.actions
export default camerasSlice.reducer