import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
// import { addROErrorLog } from "../features/globalFunctions";
// import { RegExPattern } from "../features/globalVars";
// import myUUid from "../features/myUUid";
import ROIcon from "../components/ROIcon";
import MapItem from "../components/MapItem";
import { useState, useEffect, useMemo, useRef } from "react";
import { formatTime } from "../features/formatTime";
import { getCameras } from "../features/cameras/camerasSlice";

const Map = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const { cameras } = useSelector((state) => state.cameras);
  // console.log('the cameras ', cameras)
  // const dispatch = useDispatch()
  // const { cameras } = useSelector((state) => state.cameras)
  // console.log("Mapping component", cameras)

  const [gpsCoords, setGPSCoords] = useState({ lat: 0, long: 0 });
  // console.log();
  const refMarker = useRef("gpsCoords ", gpsCoords);

  // let liveGPS = true;

  let GPS_History = localStorage.getItem("GPS_History")
    ? JSON.parse(localStorage.getItem("GPS_History"))
    : [];

  const [useGPSHistory, SetUseGPSHistory] = useState();
  // console.log("global var useGPSHistory", useGPSHistory);

  useEffect(() => {
    console.log("Map useEffect loaded");

    dispatch(getCameras());

    const interval = setInterval(() => {
      SetUseGPSHistory(localStorage.getItem("GPS_History"));
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useMemo(() => {
    // console.log("the gpsCoords Memo", gpsCoords);
    // console.log("the GPS_History Memo", GPS_History);
  }, [GPS_History]);

  const clearData = () => {
    // console.log("start GPS history ",GPS_History)
    localStorage.setItem("GPS_History", []);
    GPS_History = [];
    setGPSCoords({ lat: 0, long: 0 });
    // console.log("end GPS history ",GPS_History)
  };

  // // console.log("return GPS History ", GPS_History)
  let arrayHistoryAC = [];
  // // arrayHistoryAC = [[26.199694275856018, -29.142791051179337],
  // //           [26.19987666606903, -29.142833219138094],
  // //           [26.200300455093387, -29.142894128381307],
  // //           [26.20050966739655, -29.142936296297776],
  // //           [26.200788617134098, -29.142983149517985],
  // //           [26.201078295707706, -29.143076855894332],
  // //           [26.201115846633915, -29.143090911843398],
  // //           [26.200080513954166, -29.144145102544872]]
  if (GPS_History) {
    arrayHistoryAC = GPS_History.map((line) => {
      return [line.coords.longitude, line.coords.latitude];
    });
  }
  return (
    <>
      <div className="container">
        <a href="https://token.nextlevelalert.co.za/Map?long=26.199485425523953&lat=-29.1438265038288">
          <h6>Your Map</h6>
        </a>

        {/* <MapItem latitude={gpsCoords.lat} longitude={gpsCoords.long} CoordinatesInputElement={refMarker} arrayHistory={arrayHistoryAC}/> */}
        <MapItem
          CoordinatesInputElement={refMarker}
          arrayHistory={arrayHistoryAC}
        />
      </div>

      {/* <div className="container mt-1 pt-2">
        <div className="row pt-4">
          <div className="col ">
            <button onClick={() => clearData()} className="ClearBtn  fs-5 rounded-1  fw-bold ">
              <ROIcon
                icon="icoDel"
                size={24}
                className="icoSVG float-start"
                ROiconAfterStyle="align-bottom p-0 m-0"
                tooltip="Clear Data"
                textafter="Clear Data"
                usefill="true"
                // color1={"#fff"}
                isLink={false}
              />
            </button>
          </div>
          <div className="col-4 ">
            <button className="GPSposition fs-5 rounded-1 fw-bold text-white">
              <ROIcon
                icon="icoLocation"
                size={24}
                className="icoSVG float-start"
                ROiconAfterStyle="align-bottom p-0 m-0"
                tooltip="Locate Camera"
                textafter="Direction "
                usefill="true"
                color1={"#fff"}
                isLink={false}
              />
            </button>
          </div>
        </div>
      </div> */}

      {/*display the live tracking data */}
      {/* <div className="pageContent container">
        {GPS_History
          ? GPS_History.map((line, index) => {
              // console.log("the line ", line)
              return (
                <>
                  <div className="row">
                    <div className="col-3 text-white"> {index}  TimeStamp: {formatTime(JSON.parse(line).timestamp)}</div>
                    <div className="col-3 text-white"> Coordinates: {JSON.parse(line).coords.latitude}, {JSON.parse(line).coords.longitude}  </div>
                    <div className="col-3 text-white">
                      {" "}
                      {index} TimeStamp: {formatTime(line.timestamp)}
                    </div>
                    <div className="col-3 text-white">
                      {" "}
                      Coordinates: {line.coords.longitude},{" "}
                      {line.coords.latitude}{" "}
                    </div>
                  </div>
                </>
              );
            })
          : ""}
      </div> */}
    </>
  );
};

export default Map;
