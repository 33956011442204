import iconLib from '../features/iconsLib'
import myUUid from '../features/myUUid'
import ROIcon from './ROIcon'

const icons = []

for (const icon in iconLib) {
    icons.push(icon)
}

//        ;<h4 className='danger'>Work in progress (not implemented)</h4>

//Component to show all icons
const ShowROIcons = ({ useFill = false, color1 = '' }) => {
    return (
        <>
            <h4 className='text-success p-0 m-0 pt-4'>All App Icons:</h4>
            {icons.map((icon) => {
                return (
                    <i
                        key={myUUid()}
                        style={{
                            float: 'left',
                        }}
                        className='mt-5 mx-2'
                    >
                        <ROIcon
                            icon={icon}
                            size={64}
                            className='icoSVG'
                            tooltip={icon}
                            authlvl={99}
                            usefill={(iconLib[icon][0].fillID && iconLib[icon][0].fillID) || useFill > 0 ? true : false}
                            color1={color1}
                        />
                        <br />
                        <small className='custChatDateTime'>{icon}</small>
                    </i>
                )
            })}
        </>
    )
}

export default ShowROIcons
