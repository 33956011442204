//Format time to our standard dd/mm/yyyy - hh:mm:ss
export const formatTime = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = ('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    const hours = ('0' + t.getHours()).slice(-2)
    const minutes = ('0' + t.getMinutes()).slice(-2)
    const seconds = ('0' + t.getSeconds()).slice(-2)
    return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`
}

export const formatShortTime = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = ('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    const hours = ('0' + t.getHours()).slice(-2)
    const minutes = ('0' + t.getMinutes()).slice(-2)
    const seconds = ('0' + t.getSeconds()).slice(-2)
    return `${hours}h${minutes}m`
}

export const formatShortTimeSeconds = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    // const milisec = Math.floor(timeval%1000)/1000
    // const seconds =  Math.floor((((timeval/1000)%60)%3600) - milisec)
    // const minutes = Math.floor(((timeval/1000) - (seconds - milisec))%60)
    // const hours = Math.floor(((timeval/1000) -  ((minutes) - (seconds - milisec))) /3600)
    const seconds = Math.floor((timeval / 1000) % 60);
    const minutes = Math.floor((timeval / (1000 * 60)) % 60);
    const hours = Math.floor(timeval / (1000 * 60 * 60));
    return `${hours}h${minutes}m${seconds}s`
}

export const formatShortDate = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = t.toLocaleString('default', { month: 'short' }) //('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    return `${year} ${month} ${date}`
}

export const formatLongDate = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = t.toLocaleString('default', { month: 'long' }) //('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    return `${year} ${month} ${date}`
}

export const formatDateDay = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = t.toLocaleString('default', { month: 'long' }) //('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    return `${date} ${month} ${year}`
}

export const formatInputDate = (timeval) => {
    if (!timeval || timeval === null || timeval === '') {
        return ''
    }
    const t = new Date(timeval)
    const date = ('0' + t.getDate()).slice(-2)
    const month = ('0' + (t.getMonth() + 1)).slice(-2)
    const year = t.getFullYear()
    // const hours = ('0' + t.getHours()).slice(-2)
    // const minutes = ('0' + t.getMinutes()).slice(-2)
    // const seconds = ('0' + t.getSeconds()).slice(-2)
    //console.log('formatTime.js formatInputDate: ', `${year}-${month}-${date}`)
    return `${year}-${month}-${date}`
}
