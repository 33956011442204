import { configureStore } from "@reduxjs/toolkit";
import camerasReducer from "../features/cameras/camerasSlice.js"



export const store = configureStore({
    reducer:{
        cameras: camerasReducer
    }
})
