import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Appointment from "./pages/Appointment";
import Map from "./pages/Map";

function App() {
  const Menu = () => {
    let location = useLocation();
    console.log(`location: `, location);

    return (
      <>
        <div className="globalContainer container-fluid p-0 m-0">
          {location.pathname !== `/AppointmentToken` ? (
            <>
              <Routes>
                {/* <Route path='/' element={<DecodeDL />} /> */}
                <Route path="/" element={<Dashboard />} />
                <Route path="/Map" element={<Map />} />
              </Routes>
            </>
          ) : (
            <Appointment />
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <Router>
        <Menu />
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
