import axios from 'axios'

const API_URL = '/api/cameras/'

//Add new camera
const createCamera = async (cameraData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    //Append user credentials into data obj
   
    cameraData = { ...cameraData, user: { ...user } }

    const response = await axios.post(API_URL + '/', cameraData, headersConfig)
    return response.data
}

const updateCamera = async (cameraData, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // Get user from localStorage
    const user = JSON.parse(localStorage.getItem('user'))

    //Append user credentials into data obj
    cameraData = { ...cameraData, user: { ...user } }

    const response = await axios.put(API_URL + cameraData.cameraID, cameraData, headersConfig)

    return response.data
}

//Get Cameras
const getCameras = async () => {
    try {
        // const headersConfig = {
        //     headers: {
        //         Authorization: `Bearer ${userCreds.token}`,
        //     },
        // }

        const response = await axios.get(API_URL)

        //console.log('*************** return all users response **********************')

        return response.data
    } catch (error) {
        //console.log('camerasService(getCameras) response: ', error.response, error.response.status)

        if (error.response.status === 401) {
            throw new Error('User unauthorized. Please log in.')
        }

        throw new Error('camerasService(getCameras): ' + error.response.data)
    }
}

//Delete Camera
const deleteCamera = async (cameraID, token) => {
    const headersConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + cameraID, headersConfig)

    return response.data
}

const camerasService = {
    createCamera,
    getCameras,
    deleteCamera,
    updateCamera,
}

export default camerasService
