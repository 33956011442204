import React from "react";
import maplibregl, { Marker, NavigationControl } from "maplibre-gl";
// GeolocateControl,
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef, useState, useMemo } from "react";
// import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { formatTime } from "../features/formatTime";
// import { response } from "express";
import { solveRoute } from "@esri/arcgis-rest-routing";
import { ApiKeyManager } from "@esri/arcgis-rest-request";
import { useSearchParams } from "react-router-dom";

function MapItem({
  MapWrap = "map-wrap",
  MaplibreID = "esrieMap",
  dragMarker = false,
  latitude = 0,
  longitude = 0,
  MarkerCallBack,
  ZoomIn = 18,
  CameraMarker = true,
  CoordinatesInputElement,
  baseMapSelect = true,
  arrayHistory = [],
}) {
  const refMap = useRef(null);
  const refMapContainer = useRef(null);
  const refMapLabel = useRef(null);

  // console.log("map Item long & lay", latitude, longitude);
  const { cameras } = useSelector((state) => state.cameras);

  // console.log("arrayHistory ", arrayHistory);

  const [zoom, setZoom] = useState(ZoomIn);

  const [currentCoords, setCurrentCoords] = useState();
  // console.log("currentCoords ",currentCoords);

  let [urlParams, setUrlParams] = useSearchParams({ lat: 0, long: 0 });
  console.log("urlParams ", urlParams);
  let urlLatitude = urlParams.get("lat") ? urlParams.get("lat") : 0;
  let urlLongitude = urlParams.get("long") ? urlParams.get("long") : 0;
  console.log("lat & long draw ", urlLatitude, urlLongitude);

  // esrie access token
  const accessToken =
    "AAPTxy8BH1VEsoebNVZXo8HurMrv_42vEbP_M3llu5kdPpda8XkjV9WNBu_kARHWucCIx7F6umd8mLU499dybAVUx0pjlONimoks2e4Cb0tp6IdaD2CMltgPKhjUdyNPb7sMHu1L7GeSbZsCDx4osuq4sE1SOuORa1k4xEnAicUmtYg1PpCRDArTHq6utnnkXdRdRy8qBqsckYr3cvUC3tlIcPVuOYnZWYy2L37tRiVqZRY.AT1_76J3uSWJ";
  // esrie basemap layer
  const basemapEnum = "arcgis/navigation";

  const svgImage = `
        <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 700 700'>
            <path
                className='cls-2'
                style="fill: #FF0000"
                d='M438,382c0,48.6-39.4,88-88,88s-88-39.4-88-88,39.4-88,88-88c48.57.07,87.93,39.43,88,88Z'
            />
            <path
                className='cls-1'
                style="fill: #FF0000"
                d='M558,190h-88l-12.4-32.9c-7.02-18.71-24.91-31.11-44.9-31.1h-125.5c-19.99,0-37.88,12.39-44.9,31.1l-12.3,32.9h-88c-26.51,0-48,21.49-48,48v288c0,26.51,21.49,48,48,48h416c26.51,0,48-21.49,48-48V238c0-26.51-21.49-48-48-48ZM350,502c-66.27,0-120-53.73-120-120s53.73-120,120-120,120,53.73,120,120c-.06,66.25-53.75,119.94-120,120Z'
            />
        </svg>
    
        `;

  const geoJson = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          properties: {},
          coordinates: arrayHistory,
        },
      },
    ],
  };

  let updatedGeoJson = {
    type: "FeatureCollection",
    features: [],
  };

  cameras.map((item) => {
    if (item.CameraCoordinates) {
      updatedGeoJson.features.push({
        type: "Feature",
        properties: {
          message: `
              ${[item.CameraName]}
              <br/>
              ${[item.CameraLocationName]}
                <br/>
              ${[item.CameraIP]}
                <br/>
              ${[
                parseFloat(item.CameraCoordinates.split(",")[0]),
                parseFloat(item.CameraCoordinates.split(",")[1]),
              ]}
              `,
          iconSize: [20, 29],
        },
        geometry: {
          type: "Point",
          coordinates: [
            parseFloat(item.CameraCoordinates.split(",")[1]),
            parseFloat(item.CameraCoordinates.split(",")[0]),
          ],
        },
      });
    }
  });

  // useMemo(() => console.log("Memo current coordinates "), [currentCoords]);

  useEffect(() => {
    // toast.success(`${latitude} , ${longitude}`);
    console.log("Map Item useEffect loaded");
    // console.log("the useEffect current coords ", currentCoords);

    const interval = setInterval(() => {
      // GPS history
      let GPS_History = localStorage.getItem("GPS_History")
        ? JSON.parse(localStorage.getItem("GPS_History"))
        : [];
      // console.log("the geolocate event GPS history ", GPS_History)

      if (currentCoords && currentCoords.longitude !== 0) {
        GPS_History.push({
          timestamp: Date.now(),
          coords: {
            longitude: currentCoords ? currentCoords.longitude : 0,
            latitude: currentCoords ? currentCoords.latitude : 0,
          },
        });
      }

      localStorage.setItem("GPS_History", JSON.stringify(GPS_History));
    }, 10000);

    let currentLong = longitude;
    let currentLat = latitude;
    try {
      if (longitude === 0 && latitude === 0) {
        currentLong = urlLongitude;
        currentLat = urlLatitude;
      }
      refMap.current = new maplibregl.Map({
        container: refMapContainer.current,
        style: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/${basemapEnum}?token=${accessToken}`,
        zoom: zoom,
        center: [currentLong, currentLat],
      });
    } catch (error) {
      console.log("the maps not drawing ", error);
    }
    // console.log("the refmap ", refMap.current)
    if (refMap && refMap.current) {
      const geolocateControl = new maplibregl.GeolocateControl({
        // fitBoundsOptions: {
        //   zoom: 10,
        // },
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: false,
        showUserHeading: true,
      });

      // Add the geolocate control to the map
      refMap.current.addControl(geolocateControl);
      refMap.current.on("load", function () {
        geolocateControl.trigger();

      });

      geolocateControl.on("geolocate", (event) => {
        const { longitude, latitude } = event.coords;
        // console.log("User location:", latitude, longitude);
        // console.log("geo event ", event);
        // alert(JSON.stringify(event.coords))
        setCurrentCoords({ latitude: latitude, longitude: longitude });
        refMapLabel.current.innerHTML =
          formatTime(Date.now()) +
          " longitude: " +
          longitude +
          " latitude: " +
          latitude;

        // GPS history
        let GPS_History = localStorage.getItem("GPS_History")
          ? JSON.parse(localStorage.getItem("GPS_History"))
          : [];
        // console.log("the geolocate event GPS history ", GPS_History)
        // console.log("currentCoords useEffect ", currentCoords);
        if (latitude !== 0) {
          GPS_History.push({
            timestamp: Date.now(),
            coords: {
              longitude: longitude,
              latitude: latitude,
            },
          });
        }

        localStorage.setItem("GPS_History", JSON.stringify(GPS_History));
        // updateRoute([longitude, latitude], [26.201115678445333, -29.143990488585445]);
        // const apiKey = new ApiKeyManager.fromKey("...");
        if (longitude !== 0) {
          const authentication = ApiKeyManager.fromKey(accessToken);
          // console.log("currentCoords updateRoute ", currentCoords ? currentCoords : '');
          console.log(
            "lat",
            urlLatitude ? parseFloat(urlLatitude) : 0,
            "none parsing",
            urlLatitude
          );
          console.log(
            "long",
            urlLongitude ? parseFloat(urlLongitude) : 0,
            "none parsing",
            urlLongitude
          );

          solveRoute({
            stops: [
              [longitude ? longitude : 0, latitude ? latitude : 0],
              [
                parseFloat(urlLongitude) ? parseFloat(urlLongitude) : 0,
                parseFloat(urlLatitude) ? parseFloat(urlLatitude) : 0,
              ],
            ],
            endpoint:
              "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World/solve",
            authentication,
          })
            .then((response) => {
              refMap.current
                .getSource("route")
                .setData(response.routes.geoJson);
            })

            .catch((error) => {
              console.error(error);
              alert(
                "There was a problem using the route service. See the console for details."
              );
            });
        }

        // set marker
        // let marker = new Marker({ color: "#FF0000", draggable: true });
        // // console.log("the marker bool", dragMarker)
        // marker.setLngLat([currentLong, currentLat]);
        // // console.log("the marker", marker)
        // marker.addTo(refMap.current);
      });

      refMap.current.addControl(new maplibregl.FullscreenControl(), "top-left");

      let nav = new NavigationControl();
      refMap.current.addControl(nav, "top-right");

      refMap.current.on("zoom", () => {
        setZoom(refMap.current.getZoom());
        // console.log("the Zoom  levels trigger");
        // console.log("the Zoom  levels", zoom);
      });

      refMap.current.on("load", () => {
        refMap.current.addSource("route", {
          type: "geojson",
          data: geoJson,
        });
        refMap.current.addLayer({
          id: "LineString",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#f21637",
            "line-width": 6,
          },
        });

        const coordinates = geoJson.features[0].geometry.coordinates;

        const bounds = coordinates.reduce((bounds, coord) => {
          return bounds.extend(coord);
        }, new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
      });

      if (CameraMarker) {
        updatedGeoJson.features.forEach((marker) => {
          // console.log("the foreach marker ", marker)
          // create a DOM element for the marker

          if (refMap && refMap.current && marker) {
            const el = document.createElement("div");
            el.className = "marker";
            el.style.width = `${marker.properties.iconSize[0]}px`;
            el.style.height = `${marker.properties.iconSize[1]}px`;

            // Set the innerHTML to svgIcon
            el.innerHTML = svgImage;

            el.addEventListener("click", () => {
              // window.alert(marker);
              // console.log("the marker ", marker)
              const popup = new maplibregl.Popup({ closeOnClick: false })
                .setLngLat(marker.geometry.coordinates)
                .setHTML(marker.properties.message)
                .addTo(refMap.current);
              // console.log(marker.geometry.coordinates)
              setUrlParams({
                long: marker.geometry.coordinates[0],
                lat: marker.geometry.coordinates[1],
              });
            });

            // add marker to map
            new maplibregl.Marker({ element: el })
              .setLngLat(marker.geometry.coordinates)
              .addTo(refMap.current);
          }
        });
      }

      return () => {
        clearInterval(interval);
      };
    }
  }, [cameras, latitude, longitude, urlParams]);

  return (
    <>
      <div className={MapWrap}>
        <div ref={refMapLabel}></div>
        <div id={MaplibreID} ref={refMapContainer}></div>
      </div>
    </>
  );
}

export default MapItem;
